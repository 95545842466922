import { Injectable } from "@angular/core";
import { APIPagedResponse, AssessmentCategory, AssessmentSkill, AssessmentSubCategory } from "models";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { Observable } from "rxjs";
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class RemarksService extends CRUDService<any> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.academic + "/");
    }

    getTeacherRemarkSetup(data: any): Observable<any[]> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.remarks}?${query}`);
    }

    addTeacherRemarkSetup(data: any): Observable<any> {
        console.log(data)
        return this.baseService.post(`${APIConstant.remarks}`, data);
    }

    updateTeacherRemarkSetup(data: any): Observable<any> {
        return this.baseService.put(`${APIConstant.remarks}`, data);
    }

    deleteTeacherRemarkSetup(data: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.delete(`${APIConstant.remarks}?${query}`);
    }
}
