import { Injectable } from '@angular/core';
import { APIConstant } from "../constant";
import { MappedUnMappedResponse, StudentHouseMappingType, StudentMapping, StudentSubjectMapping } from 'models';
import { BaseService, CommonUtility, CRUDService } from 'projects/common/src/public-api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class StudentSubjectMappingService {

  constructor(protected baseService: BaseService) { }

  getStudentMappedToSubject(data: any): Observable<StudentSubjectMapping[]> {
    const params = CommonUtility.convertObjectToParams(data);
    return this.baseService.get<StudentSubjectMapping[]>(`${APIConstant.studentMappedToSubject}?${params}`);
  }

  addOrUpdateStudentsMappedToSubject(queryParams: any, data: any): Observable<MappedUnMappedResponse<StudentMapping>> {
    const params = CommonUtility.convertObjectToParams(queryParams);
    return this.baseService.post<MappedUnMappedResponse<StudentMapping>>(`${APIConstant.studentMappedToSubject}?${params}`, data);
  }

  copySubjectTeacherSetup(data: any) {
    return this.baseService.post<any>(`${APIConstant.copySubjectSetup}?referenceClassroomId=${data.referenceClassroomId}&copyTeacher=${data.copyTeacher}`, data.classroomIdList);
  }
}
