import { Injectable } from "@angular/core";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { Observable } from "rxjs";
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class AcademicService extends CRUDService<any> {

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.academic + "/");
    }

    getAcademicYear(): Observable<any[]>{
        return this.baseService.get(`${APIConstant.academicYearSetup}`);
    }

    getFutureAcademicYears(): Observable<any[]>{
        return this.baseService.get(`${APIConstant.futureAcademicYears}`);
    }

    changeCurrentYear(data:any): Observable<any[]>{
        return this.baseService.put(`${APIConstant.changeCurrentYear}`,data);
    }

    add(data: any): Observable<any> {
        return this.baseService.post<any>(APIConstant.academicYearSetup, data);
    }

    update(id: number, data: any): Observable<any> {
        let url: string = APIConstant.academicYearSetup;
        if (id) {
            url += `${id}`;
        }
        return this.baseService.put<any>(url, data);
    }
}
