import { Injectable } from "@angular/core";
import { APIPagedResponse, AssessmentCategory, AssessmentSkill, AssessmentSubCategory } from "models";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { Observable } from "rxjs";
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class ResultService extends CRUDService<any> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.academic + "/");
    }

    getStudentResultSetup(data: any): Observable<any[]> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.result}?${query}`);
    }

    addStudentResultSetup(data: any): Observable<any> {
        return this.baseService.post(`${APIConstant.result}`, data);
    }

    updateStudentResultSetup(data: any): Observable<any> {
        return this.baseService.put(`${APIConstant.result}`, data);
    }

    deleteStudentResultSetup(data: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.delete(`${APIConstant.result}?${query}`);
    }
}
