export * from "./assessment.service";
export * from "./remarks.service";
export * from "./academic.service";
export * from "./result.service";
export * from './subject-setup.service';
export * from './academic-subject-classroom.service';
export * from './student-evaluation.service';
export * from './student-house-mapping.service';
export * from './assessment-skill.service';
export * from './student-subject-mapping.service';
export * from './timetable.service';
