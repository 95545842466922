import { Injectable } from "@angular/core";
import { AssessmentCategory, AssessmentSubCategory, Category } from "models";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { Observable } from "rxjs";
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class AssessmentService extends CRUDService<any> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.academic + "/");
    }

    getSubjectsByClassroomId(data: any): Observable<any[]> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.subjectByClassroom}?${query}`);
    }

    getAssessmentCategories(data: any): Observable<AssessmentCategory[]> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.assessmentCategories}?${query}`);
    }

    addAssessmentCategories(data: AssessmentCategory): Observable<any> {
        return this.baseService.post(`${APIConstant.assessmentCategories}`, data);
    }

    updateAssessmentCategories(data: AssessmentCategory): Observable<any> {
        return this.baseService.put(`${APIConstant.assessmentCategories}`, data);
    }

    getAssessmentSubCategories(data: any): Observable<AssessmentSubCategory[]> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.assessmentSubCategories}?${query}`);
    }

    addAssessmentSubCategories(data: AssessmentSubCategory): Observable<any> {
        return this.baseService.post(`${APIConstant.assessmentSubCategories}`, data);
    }

    addAssessmentSkill(data: any): Observable<any> {
        return this.baseService.post(`${APIConstant.assessmentSkills}`, data);
    }

    updateAssessmentSubCategories(data: AssessmentSubCategory): Observable<any> {
        return this.baseService.put(`${APIConstant.assessmentSubCategories}`, data);
    }

    getSkills(data: any): Observable<AssessmentCategory[]> {
        const reqParams = CommonUtility.convertObjectToParams(data);
        if (data && Array.isArray(data.subjectId)) {
            for (const sub of data.subjectId) {
                reqParams.append('subjectId', sub);
            }
        }
        return this.baseService.get<AssessmentCategory[]>(`${APIConstant.assessmentSkills}?${reqParams}`);
    }

}
