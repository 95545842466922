import { Injectable } from '@angular/core';
import { APIConstant } from "../constant";
import { StudentHouseMappingType } from 'models';
import { BaseService, CommonUtility, CRUDService } from 'projects/common/src/public-api';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class StudentHouseMappingService extends CRUDService<StudentHouseMappingType> {

  constructor(protected baseService: BaseService) {
    super(baseService, APIConstant.categoryMapping + "/");
  }

  getStudentHouseMappingByClassroomId(classroomId: number): Observable<StudentHouseMappingType[]> {
    const params = CommonUtility.convertObjectToParams({ classroomId });
    return this.baseService.get<StudentHouseMappingType[]>(`${APIConstant.categoryMapping}?${params}`);
  }

  updateStudentHouseMapping(categoryMapping: any): Observable<any> {
    return this.baseService.put<any>(`${APIConstant.categoryMapping}`, categoryMapping);
  }

  getStudentHouseMappingByProfileId(studentProfileId: number): Observable<StudentHouseMappingType> {
    const params = CommonUtility.convertObjectToParams({ studentProfileId });
    return this.baseService.get<StudentHouseMappingType>(`${APIConstant.categoryMapping}?${params}`);
  }
}
