
export const CommonConstant = {
    token: "token",
    user: "user",
    studentId: "studentId",
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
};


export const Images = {
    user: "assets/images/user.png",
    book: "assets/images/add-book.png",
};

export const OptionSubject: string[] = ["Y", "N"];
