import { Injectable } from "@angular/core";
import { AssessmentSkill } from 'models';
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class AssessmentSkillService extends CRUDService<AssessmentSkill> {

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.assessmentSkills);
    }

}
