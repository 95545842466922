import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { AcademicTimeTableList, Timetable } from "models";

@Injectable({
    providedIn: "root",
})
export class TimetableService extends CRUDService<AcademicTimeTableList> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.timetable);
    }

    getOccupancy(data: any): Observable<any[]> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.occupancy}?${query}`);
      }

      addTimetable(data: any): Observable<any[]> {
        return this.baseService.post(`${APIConstant.timetable}`, data);
      }

      updateTimetable(data: any): Observable<any[]> {
        return this.baseService.put(`${APIConstant.timetable}`, data);
      }
}
