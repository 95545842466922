import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,

    //academic: `${apiPath}academic`,
    assessmentCategories: `${apiPath}/academics/assessments/categories`,
    assessmentSubCategories: `${apiPath}/academics/assessments/sub-categories`,
    assessmentSkills: `${apiPath}/academics/assessments/skills`,
    subjectByClassroom: `${apiPath}/academics/subjects/byClassroom`,
    remarks: `${apiPath}/academics/marks/teacher-remarks/setup`,
    result: `${apiPath}/academics/marks/results/setup`,
    academic: `${apiPath}/academic`,
    academicSubjects: `${apiPath}/academics/subjects`,
    academicYearSetup: `${apiPath}/academics/academic-year-setup`,
    futureAcademicYears: `${apiPath}/academics/academic-year-setup/future`,
    changeCurrentYear: `${apiPath}/academics/academic-year-setup/change/current-year`,
    academicSubjectsByClassroom: `${apiPath}/academics/subjects/byClassroom`,
    marks: `${apiPath}/academics/marks`,
    results: `${apiPath}/academics/marks/results`,
    teacherRemarks: `${apiPath}/academics/marks/teacher-remarks`,
    gradeSetup: `${apiPath}/academics/grade/setup`,
    reportCardSetup: `${apiPath}/academics/reportCard/setup`,
    reportCardTemplate: `${apiPath}/academics/reportCard/template`,
    progressReport: `${apiPath}/academics/reportCard/progressReport`,
    exams: `${apiPath}/academics/exams`,
    changeTestOrder: `${apiPath}/academics/exams/tests/changeOrder`,
    changeExamOrder: `${apiPath}/academics/exams/changeOrder`,
    examSchedule: `${apiPath}/academics/exams/schedule`,
    tests: `${apiPath}/academics/exams/tests`,
    publish: `${apiPath}/academics/exams/publish`,
    categoryMapping: `${apiPath}/academics/house-mapping`,
    studentPromote: `${apiPath}/academics/students-to-promote`,
    studentDemote: `${apiPath}/academics/students-to-demote`,
    classRoomPromote: `${apiPath}/school/classrooms/classes-to-promote`,
    classRoomDemote: `${apiPath}/school/classrooms/classes-to-demote`,
    promote: `${apiPath}/academics/promote`,
    demote: `${apiPath}/academics/demote`,
    subjectChangeOrder: `${apiPath}/academics/subjects/changeOrder`,
    byClassroomChangeOrder: `${apiPath}/academics/subjects/byClassroom/changeOrder`,
    studentMappedToSubject: `${apiPath}/academics/subjects/student-mapping`,
    timetable: `${apiPath}/academics/timetable`,
    occupancy: `${apiPath}/academics/timetable/occupancy`,
    hallTicket: `${apiPath}/academics/hallticket`,
    hallTicketDownload: `${apiPath}/academics/hallticket/download`,
    deleteExam: `${apiPath}/academics/exams`,
    generateProgressReport: `${apiPath}/documents/get`,
    defaultSubjects: `${apiPath}/academics/subjects/default`,
    copyGradeSetup: `${apiPath}/academics/grade/copy-grade-setup`,
    copySubjectSetup: `${apiPath}/academics/subjects/byClassrooms/copy-from-ref-classroom`,
    copyExamSetup: `${apiPath}/academics/exams/copyExamSetup/byClassroomIdsAndExamId`,
    copyExamScheduleSetup: `${apiPath}/academics/exams/copyExamSchedule/byClassroomIdsAndExamid`,
    predictedSchedule: `${apiPath}/academics/exams/predicted-schedule`,
    getHolidays: `${apiPath}/calendar/holidays/next30days`,
    getDigitalDiary: `${apiPath}/academics/digitalDiary`,
    nonUsers: `${apiPath}/security/non-users`,
    loginEmployee: `${apiPath}/hr/employees/all`,
    classWiseSubjects: `${apiPath}/academics/subjects/byClassroom/list`,
    getSubjects:`${apiPath}/academics/subjects/default`,
    defaultSubject:`${apiPath}/academics/subjects/list`

};

export const PublicAPI = [];
