import { APIConstant } from '../constant';
import { Injectable } from '@angular/core';
import { Marks, Remarks, StudentMarks } from 'models';
import { CommonUtility, CRUDService } from 'projects/common/src/lib';
import { BaseService } from 'projects/common/src/lib/service/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentEvaluationService extends CRUDService<any> {

  constructor(protected baseService: BaseService) {
    super(baseService, APIConstant.academic + "/");
  }

  getStudentMarks(query: string): Observable<StudentMarks[]> {
    return this.baseService.get(`${APIConstant.marks}?${query}`);
  }

  updateStudentMarks(marks: Marks[]): Observable<Marks[]> {
    return this.baseService.post(APIConstant.marks, marks);
  }

  getStudentResults(data: any): Observable<any[]> {
    const query = CommonUtility.convertObjectToParams(data);
    return this.baseService.get(`${APIConstant.results}?${query}`);
  }

  getTeacherRemarks(data: any): Observable<Remarks[]> {
    const query = CommonUtility.convertObjectToParams(data);
    return this.baseService.get(`${APIConstant.teacherRemarks}?${query}`);
  }

  saveStudentResults(data: any[]): Observable<any[]> {
    return this.baseService.post(`${APIConstant.results}`, data);
  }

  saveTeacherRemarks(data: any[]): Observable<Remarks[]> {
    return this.baseService.post(`${APIConstant.teacherRemarks}`, data);
  }
}
